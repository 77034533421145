// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// react router
import { Link, useLocation } from 'react-router';

// mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Stack,
  useMediaQuery,
  Skeleton,
  Card,
  Alert,
  AlertTitle,
  Switch,
  Divider,
  Snackbar,
  Tooltip,
  CircularProgress,
  Chip,
  Box,
  List,
  ListItem,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import { DirectionsCar as DirectionsCarIcon, FiberManualRecord, Info as InfoIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { TimePicker } from '@mui/x-date-pickers';

import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

// dayjs
import dayjs from 'dayjs';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { clearOtherData } from 'store/features/snackbarSlice';

import { useSnackbar } from 'notistack';

// project constants
import { affiliateBookingStatuses, bookingStatuses, weekDays } from 'constants/bookings';
import userTypes from 'constants/userTypes';
import ManagerAccessRolePermissions from 'constants/managerAccessRolePermissions';
import { gridSpacing } from 'store/constant';
import { managerLocationOptions } from 'constants/managerOptions';

// project utils
import { useGetEntityName } from 'utils/entities';
import {
  dateIsValidAtLocation,
  getMobileServiceProviderTimeSlots,
  getCreatedFrom,
  getDateWithCustomTime,
  dateIsValidPerRRulesOfAllGZLocations,
  getSelectedGZLocationPerRRule,
  getPreferredTimeFrameLabel
} from 'utils/booking';
import handleError from 'utils/handle-error';
import callAzureFunction from 'utils/call-azure-function';
import callAzureFunctionPublic from 'utils/call-azure-function-public';

// project components
import BookingLocationCollisionDialog from 'ui-component/dialogs/BookingLocationCollisionDialog';
import BookingsCollisionDialog from 'ui-component/dialogs/BookingsCollisionDialog';
import getSnackbarMessageType from 'utils/snackbar-message';
import EmailBookingDialog from 'views/shared/manage-bookings/EmailBookingDialog';
import ChangeBookingTypeDialog from './ChangeBookingTypeDialog';
import { CustomStaticDatePicker } from 'ui-component/pickers/DateTimePickers';
import HtmlTooltip from 'ui-component/tooltips/HtmlTooltip';

const CustomTimeTextField = (params) => {
  const { InputProps } = params;
  return (
    <TextField
      {...params}
      variant="outlined"
      InputProps={{ ...InputProps, startAdornment: <InputAdornment position="start">Choose time</InputAdornment> }}
    />
  );
};
//= =============================|| ViewMobileBookingDialog ||==============================//

const ViewMobileBookingDialog = ({ open, setOpen, bookingId, getBookings, enableStatusUpdate, isReadOnly = false }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const appConfig = useSelector((state) => state.appConfig?.data);
  const googleCalendarReadAccess = Boolean(appConfig?.integrations?.googleCalendar?.calendarApiRead);

  const bookingEntityName = useGetEntityName('booking');
  const surveysEntityName = useGetEntityName('surveys');
  // const bookingsEntityName = useGetEntityName('bookings');
  const managerEntityName = useGetEntityName('manager');
  const respondentEntityName = useGetEntityName('respondent');

  // const appConfig = useSelector((state) => state.appConfig?.data);
  const user = useSelector((state) => state.user.data);
  const permissions = useSelector((state) => state.user.permissions);

  const pageLocation = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [booking, setBooking] = useState({});
  const [loadingBooking, setLoadingBooking] = useState(true);
  const [updatingBooking, setUpdatingBooking] = useState(false);
  const [viewEmailBookingDialogOpen, setViewEmailBookingDialogOpen] = useState(false);
  const [openChangeBookingTypeDialog, setOpenChangeBookingTypeDialog] = useState(false);

  const [managerHasGoogleCalendar, setManagerHasGoogleCalendar] = useState(false);
  const [managerHasOutlookCalendar, setManagerHasOutlookCalendar] = useState(false);

  const {
    Manager: manager,
    Respondent: respondent,
    Service: currentService,
    // anyManagerAtLocation,
    respondentAvailabilityIsKnown,
    affiliateSelected,
    date,
    startHours,
    startMinutes,
    endHours,
    endMinutes,
    status,
    // mobile booking attributes
    mobileBookingClientAddress,
    mobileBookingPlaceId,
    ManagerGroundZeroLocation: bookedGroundZeroLocation,
    travelTimeBufferMinutes,
    preferredTimeFrame
  } = booking;

  const bookingCreatedFrom = useMemo(() => getCreatedFrom(booking), [booking]);

  // change status
  const [newStatus, setNewStatus] = useState(null);

  // change service
  const [changeService, setChangeService] = useState(false);
  const [newService, setNewService] = useState(null);
  const [services, setServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(false);
  const [disableChangeService, setDisableChangeService] = useState(false);
  const service = newService || currentService;

  // change location
  // const [changeLocation, setChangeLocation] = useState(false);
  // const [newLocation, setNewLocation] = useState(null);
  // const location = newLocation || currentLocation;

  // change manager
  const [newManager, setNewManager] = useState(null);
  const [changeManager, setChangeManager] = useState(false);
  const [newManagerOption, setNewManagerOption] = useState(null);

  const [timezonePerMobileBookingPlaceId, setTimezonePerMobileBookingPlaceId] = useState(null);

  const [ignoreGroundZeroLocation, setIgnoreGroundZeroLocation] = useState(false);

  const [selectedMSP, setSelectedMSP] = useState(null);
  const [newGroundZeroLocation, setNewGroundZeroLocation] = useState(null);

  const selectedGroundZeroLocation = newGroundZeroLocation ?? bookedGroundZeroLocation;

  const [availableManagersMSP, setAvailableManagersMSP] = useState([]);
  const [loadingAvailableManagers, setLoadingAvailableManagers] = useState(false);
  const [disableChangeManager, setDisableChangeManager] = useState(false);

  // change date/time (reschedule)
  const [changeDateTime, setChangeDateTime] = useState(false);
  const [newDate, setNewDate] = useState(dayjs(date));
  const [newTimeSlot, setNewTimeSlot] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

  // loading time slots indicator
  const [loadingTimeSlotsCount, setLoadingTimeSlotsCount] = useState(0);
  const startLoadingTimeSlots = () => setLoadingTimeSlotsCount((count) => count + 1);
  const stopLoadingTimeSlots = () => setLoadingTimeSlotsCount((count) => Math.max(count - 1, 0));

  const loadingTimeSlots = loadingTimeSlotsCount > 0;

  const [disableChangeDateTime, setDisableChangeDateTime] = useState(false);
  // change date/time (affiliate)
  const [oldTimeSlot, setOldTimeSlot] = useState(null);
  // change location (affiliate)
  // error
  const [error, setError] = useState(null);

  // email event logs
  const [isLoadingEmailEvent, setIsLoadingEmailEvent] = useState(false);
  const [latestEmailEvent, setLatestEmailEvent] = useState(null);
  const latestEmailEventTime = latestEmailEvent?.last_event_time ? dayjs(latestEmailEvent?.last_event_time) : null;

  // delete
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteTypedText, setDeleteTypedText] = useState('');
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
  const [deleteSnackbarMessage, setDeleteSnackbarMessage] = useState('');
  const [deleteAssociatedSurvey, setDeleteAssociatedSurvey] = useState(false);

  // Define your state for dialog
  const [collisionDialog, setCollisionDialog] = useState({
    open: false,
    title: '',
    content: null
  });

  // find closest locations or not
  const [availableGZLocations, setAvailableGZLocations] = useState([]);
  const [isLoadingClosestMSPs, setIsLoadingClosestMSPs] = useState(false);
  const [closestMSPs, setClosestMSPs] = useState([]);

  useEffect(() => {
    if (changeManager && newManager) {
      setSelectedMSP(newManager);
    } else {
      setSelectedMSP(
        manager && {
          ...manager,
          gzLocations: manager?.ManagerGroundZeroLocations
        }
      );
    }
  }, [changeManager, manager, newManager]);

  const generateSlotFromStartValues = useCallback((service, date, startHours, startMinutes) => {
    const slot = {};
    slot.start = date ? dayjs(date).hour(startHours).minute(startMinutes).toDate() : dayjs().hour(7).minute(startMinutes).toDate();
    slot.end = dayjs(slot.start).add(service.duration, 'minute').toDate();
    return slot;
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (affiliateSelected && service && !isNaN(startHours) && !isNaN(startMinutes)) {
      const slot = generateSlotFromStartValues(service, date, startHours, startMinutes);
      setNewTimeSlot(slot);
      setOldTimeSlot(slot);
    }
  }, [affiliateSelected, service, startHours, startMinutes, generateSlotFromStartValues, date]);

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteTypedText('');
    setDeleteConfirmationOpen(false);
    setDeleteAssociatedSurvey(false);
  };

  const isUnconfirmedBooking = useMemo(() => Boolean(status === null) || Boolean(preferredTimeFrame), [status, preferredTimeFrame]);

  useEffect(() => {
    if (isUnconfirmedBooking) {
      setChangeManager(true);
    }
  }, [isUnconfirmedBooking]);

  // only used for unconfirmed mobile bookings with no closest MSPs available
  const handleAssignMSPwithGroundZeroLocationsIgnored = (_, value) => {
    setNewManager({
      ...value,
      overallGZWorkDays: [0, 1, 2, 3, 4, 5, 6] // all work days made available
    });
    setNewGroundZeroLocation(null);
  };

  // callback functions and side effects
  // exclusive for unconfirmed mobile bookings

  const getTimezonePerMobileBookingPlaceId = useCallback(async () => {
    const response = await callAzureFunction({
      url: 'google-maps-geocode-by-place-id',
      method: 'post',
      data: { placeId: mobileBookingPlaceId }
    });

    setTimezonePerMobileBookingPlaceId(response?.data?.timezone);
  }, [mobileBookingPlaceId]);

  useEffect(() => {
    if (ignoreGroundZeroLocation) {
      getTimezonePerMobileBookingPlaceId();
    }
  }, [ignoreGroundZeroLocation, getTimezonePerMobileBookingPlaceId]);

  const getClosestMSPs = useCallback(async () => {
    try {
      setIsLoadingClosestMSPs(true);

      if (!availableGZLocations.length || !mobileBookingPlaceId) {
        return;
      }

      const origins = [`place_id:${mobileBookingPlaceId}`];

      const destinations = availableGZLocations.map((gzLoc) => `place_id:${gzLoc.placeId}`);

      const response = await callAzureFunctionPublic({
        url: `public/google-maps-distance-matrix-for-msp`,
        method: 'post',
        data: { origins, destinations }
      });

      let validGZLocations = [];

      // map locations including distance metrics
      const availableGZLocationsCopy = [...availableGZLocations]
        .map((location, index) => ({
          ...location,
          travelDistance: response.data[index]?.distance?.value ?? -1,
          travelDistanceText: response.data[index]?.distance?.text || '-',
          travelDurationText: response.data[index]?.duration?.text || '-',
          travelDurationValue: response.data[index]?.duration?.value ?? -1,
          straightLineDistanceInKM: response.data[index]?.straightLineDistanceInKM ?? -1,
          straightLineDistanceText: response.data[index]?.straightLineDistanceText || '-'
        }))
        // only include locations with a valid distance value and within the radius and max travel duration
        .filter((location) => {
          const isLocationInRadius = location.straightLineDistanceInKM <= location.radius;
          const isLocationWithinMaxTravelTime =
            location.maxTravelDuration > 0
              ? location.travelDurationValue > -1 && Boolean(location.travelDurationValue <= location.maxTravelDuration * 60)
              : true;

          const evaluate = location.travelDistance > -1 && isLocationInRadius && isLocationWithinMaxTravelTime;

          return evaluate;
        });

      validGZLocations = [...validGZLocations, ...availableGZLocationsCopy];

      validGZLocations.sort((a, b) => a.distance - b.distance);

      const finalGZLocationsByMSP = validGZLocations.reduce((acc, current) => {
        let accumulatedGZLocations;

        const { managerId, managerName, emailAddress, travelTimeBufferMinutes } = current;

        const currGZLocation = { ...current };

        // Get list of accumulated manager IDs so far
        const accumulatedManagerIdsSoFar = acc.map((ea) => ea.managerId);

        if (accumulatedManagerIdsSoFar.includes(current.managerId)) {
          /*
           *   If the current manager is already in the accumulator,
           *   update the data to include only the ground zero locatiom
           *   and consolidate the overall ground zero workdays so far
           */
          const updatedAccumulatedData = [...acc].map((manager) => {
            let mappedData;

            if (manager.managerId === current.managerId) {
              /*
               *  Update the location with new ground zero location and consolidated workdays
               */
              mappedData = {
                ...manager,
                gzLocations: [...manager.gzLocations, currGZLocation]
              };
            } else {
              mappedData = manager;
            }

            return mappedData;
          });

          accumulatedGZLocations = updatedAccumulatedData;
        } else {
          /*
           * If the current manager is not in the accumulator, add a new entry
           */
          const consolidatedData = {
            managerId,
            managerName,
            emailAddress,
            travelTimeBufferMinutes: travelTimeBufferMinutes || 0,
            gzLocations: [currGZLocation]
          };

          accumulatedGZLocations = [...acc, consolidatedData];
        }

        return accumulatedGZLocations;
      }, []);

      setClosestMSPs(finalGZLocationsByMSP);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoadingClosestMSPs(false);
    }
  }, [availableGZLocations, mobileBookingPlaceId]);

  const isBookingSafe = async () => {
    if (!selectedGroundZeroLocation) {
      // booking with no location cannot have collision
      return true;
    }

    let startDate;
    let endDate;

    if (!newTimeSlot) {
      const newSlot = generateSlotFromStartValues(service, date, startHours, startMinutes);
      startDate = dayjs(newSlot?.start);
      endDate = dayjs(newSlot?.end);
    } else {
      startDate = dayjs(newTimeSlot?.start);
      endDate = dayjs(newTimeSlot?.end);
    }

    if (!ignoreGroundZeroLocation) {
      // assuming start and end hours/minutes are integers
      // assuming start and end hours/minutes are integers
      let { workStartMinutes, workEndMinutes, lunchStartMinutes, lunchEndMinutes } = selectedGroundZeroLocation;
      const { workStartHours, workEndHours, lunchStartHours, lunchEndHours } = selectedGroundZeroLocation;
      // Convert times into minutes for easy comparison
      const startMinutesTotal = startDate.hour() * 60 + startDate.minute();
      const endMinutesTotal = endDate.hour() * 60 + endDate.minute();
      workStartMinutes = workStartHours * 60 + workStartMinutes;
      workEndMinutes = workEndHours * 60 + workEndMinutes;
      lunchStartMinutes = lunchStartHours * 60 + lunchStartMinutes;
      lunchEndMinutes = lunchEndHours * 60 + lunchEndMinutes;
      // Validate work hours and days
      if (startMinutesTotal < workStartMinutes || endMinutesTotal > workEndMinutes) {
        setCollisionDialog({
          open: true,
          title: 'Work Hours Collision',
          content: <BookingLocationCollisionDialog location={selectedGroundZeroLocation} />
        });
        return false;
      }
      // Validate lunch break
      if (
        lunchStartHours !== null &&
        lunchStartMinutes !== null &&
        lunchEndHours !== null &&
        lunchEndMinutes !== null &&
        ((startMinutesTotal >= lunchStartMinutes && startMinutesTotal < lunchEndMinutes) ||
          (endMinutesTotal > lunchStartMinutes && endMinutesTotal <= lunchEndMinutes) ||
          (startMinutes <= lunchStartMinutes && endMinutes >= lunchEndMinutes))
      ) {
        setCollisionDialog({
          open: true,
          title: 'Lunch Break Collision',
          content: <BookingLocationCollisionDialog location={selectedGroundZeroLocation} />
        });
        return false;
      }
    }

    const checkForCollision = async () => {
      try {
        await callAzureFunctionPublic({
          url: '/public/bookings-find-collision',
          method: 'get',
          params: {
            managerId: selectedMSP?.id,
            startDate: dayjs(startDate).utc().format(), // Convert start date to UTC
            endDate: dayjs(endDate).utc().format(), // Convert end date to UTC
            bookingId,
            isMobileBooking: true
          }
        });

        // google calendar collision check
        if (googleCalendarReadAccess) {
          await callAzureFunctionPublic({
            url: '/public/google-calendar/find-collision',
            method: 'get',
            params: {
              managerId: selectedMSP?.id,
              date: dayjs(newDate).format('YYYY-MM-DD'),
              startTime: dayjs(startDate).set('s', 0).format('HH:mm:ss'),
              endTime: dayjs(endDate).set('s', 0).format('HH:mm:ss')
            }
          });
        }

        return false;
      } catch (error) {
        const msg = handleError(error);
        return msg;
      }
    };

    if (newStatus?.value === bookingStatuses.Upcoming.value || (status === bookingStatuses.Upcoming.value && !newStatus?.value)) {
      const error = await checkForCollision();

      if (error) {
        setCollisionDialog({
          open: true,
          title: 'Booking Collision',
          content: <BookingsCollisionDialog bookings={[]} />
        });
        return false;
      }
    }

    return true;
  };

  const deleteBooking = useCallback(
    async (bookingId) => {
      try {
        setLoadingBooking(true);

        await callAzureFunction({
          url: `bookings`,
          method: 'delete',
          params: { bookingId, deleteAssociatedSurvey }
        });

        // don't reload on booking calendar as websocket message will cause reload instead
        // since getBookings is now an optional prop, getBookings() can only be executed if it is defined in the first place
        if (pageLocation?.pathname !== '/manage-booking-calendar' && Boolean(getBookings)) {
          getBookings();
        }
        setDeleteSnackbarMessage('Booking deleted');
        setDeleteSnackbarOpen(true);
      } catch (error) {
        const errorMessage = handleError(error);
        setDeleteSnackbarMessage(errorMessage);
        setDeleteSnackbarOpen(true);
      }
    },
    [deleteAssociatedSurvey, getBookings, pageLocation?.pathname]
  );

  const handleDelete = () => {
    deleteBooking(bookingId);
    closeSnackbar();
    setOpen(false);
    setDeleteConfirmationOpen(false);
    setDeleteTypedText(null);
    setDeleteAssociatedSurvey(false);
  };

  const formattedDate = useMemo(() => (date ? dayjs(date).format('DD/MM/YYYY') : null), [date]);
  const formattedTime = useMemo(() => {
    if (startHours === null || startMinutes === null || endHours === null || endMinutes === null) return 'TBD';

    const formattedStartTime = dayjs(getDateWithCustomTime(startHours, startMinutes)).format('hh:mm a');
    const formattedEndTime = dayjs(getDateWithCustomTime(endHours, endMinutes)).format('hh:mm a');

    return formattedStartTime && formattedEndTime ? `${formattedStartTime} - ${formattedEndTime}` : null;
  }, [startHours, startMinutes, endHours, endMinutes]);

  const preferredTimeFormatted = useMemo(() => {
    const formattedStartTime = dayjs(getDateWithCustomTime(startHours, startMinutes)).format('hh:mm a');

    return formattedStartTime ? `${formattedStartTime}` : null;
  }, [startHours, startMinutes]);

  const bookingStatus = useMemo(() => {
    let bookingStatus;
    if (affiliateSelected) {
      bookingStatus = Object.values(affiliateBookingStatuses).find((bs) => bs.value === status);
    }
    if (!affiliateSelected || !bookingStatus) {
      bookingStatus = Object.values(bookingStatuses).find((bs) => bs.value === status);
    }
    return bookingStatus;
  }, [affiliateSelected, status]);

  const canDetailsUpdate = true;

  const getStatusOptions = () => {
    if (affiliateSelected) {
      return Object.values(affiliateBookingStatuses).filter((each) => each.value);
    }
    return Object.values(bookingStatuses);
  };

  const shouldUpdateBTNRender = () => {
    const isChangeInTimeSlot = newTimeSlot && newTimeSlot?.start?.valueOf() !== oldTimeSlot?.start?.valueOf();
    const hasValuesChanged = Boolean(
      (newService && newService.id !== currentService.id) ||
        newManagerOption ||
        newManager ||
        isChangeInTimeSlot ||
        (affiliateSelected && newDate && dayjs(newDate).format('YYYY-MM-DD') !== dayjs(date).format('YYYY-MM-DD'))
    );

    // handles case where time picker has empty value or has invalid HH:MM:AMPM due to user input
    // eslint-disable-next-line no-restricted-globals
    if (affiliateSelected && isChangeInTimeSlot && (!newTimeSlot?.start || isNaN(newTimeSlot?.start?.valueOf()))) {
      return false;
    }

    if (enableStatusUpdate) {
      if (bookingStatus && newStatus && bookingStatus.value !== newStatus?.value) {
        return true;
      }

      if (newStatus) {
        return hasValuesChanged;
      }
    }

    return (canDetailsUpdate || affiliateSelected) && hasValuesChanged;
  };

  const isStatusFromAutocompletedToUpcoming = Boolean(
    status === bookingStatuses.Autocompleted.value && newStatus?.value.getTime === bookingStatuses.Upcoming.value
  );

  const canUpdateBooking = () => {
    if (isStatusFromAutocompletedToUpcoming) {
      // Allow updating of booking status from Autocompleted to Upcoming if the following conditions are met:
      // a) the change date time workflow has been selected as per changeDateTime boolean state

      // b) a new time slot has been changed
      const hasTimeSlotChanged = Boolean(newTimeSlot);

      return changeDateTime && hasTimeSlotChanged;
    }

    if (isUnconfirmedBooking) {
      // allow updating of unconfirmed mobile booking
      // if the timeSlot has changed and an MSP selected

      const hasTimeSlotChanged = Boolean(newTimeSlot);
      return changeDateTime && hasTimeSlotChanged && selectedMSP?.id;
    }

    return true;
  };

  const getBooking = useCallback(async () => {
    try {
      setLoadingBooking(true);
      const response = await callAzureFunction({ url: `/bookings/${bookingId}`, method: 'get' });
      setBooking(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingBooking(false);
    }
  }, [bookingId]);

  const getLatestEmailEventLog = useCallback(async () => {
    try {
      setIsLoadingEmailEvent(true);
      const response = await callAzureFunction({ url: `/sendgrid/get-latest-email-event/${bookingId}`, method: 'get' });
      setLatestEmailEvent(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoadingEmailEvent(false);
    }
  }, [bookingId]);

  useEffect(() => {
    if (open && bookingId) {
      getBooking();
      getLatestEmailEventLog();
    }
  }, [open, bookingId, getBooking, getLatestEmailEventLog]);

  useEffect(() => {
    if (open) {
      setNewStatus(bookingStatus || null);
    }
  }, [open, bookingStatus]);

  useEffect(() => {
    if (newStatus !== bookingStatuses.Upcoming) {
      setChangeManager(false);
      setChangeManager(false);
      setChangeDateTime(false);
    }
  }, [newStatus]);

  const handleClose = () => {
    setLoadingBooking(true);
    dispatch(clearOtherData());
    // reset service
    setNewService(null);
    setChangeService(false);
    setDisableChangeService(false);
    // reset manager
    setNewManager(null);
    setNewManagerOption(null);
    setChangeManager(false);
    setDisableChangeManager(false);
    // reset date time
    setNewDate(dayjs());
    setNewTimeSlot(null);
    setAvailableTimeSlots([]);
    setChangeDateTime(false);
    setDisableChangeDateTime(false);
    // reset MSP states
    setClosestMSPs([]);
    setAvailableGZLocations([]);
    setNewGroundZeroLocation(null);
    setSelectedMSP(null);
    setIgnoreGroundZeroLocation(false);
    // error
    setError(null);
    // calendar integration event states
    setManagerHasGoogleCalendar(false);
    setManagerHasOutlookCalendar(false);
    if (updatingBooking) {
      setOpen(true);
      setLoadingBooking(false);
    } else {
      setOpen(false);
      setLoadingBooking(false);
    }
  };

  const getGoogleCalendar = async ({ subject }) => {
    if (!subject) {
      setManagerHasGoogleCalendar(false);
      return;
    }

    try {
      await callAzureFunction({
        method: 'get',
        url: 'google-calendar/calendars',
        params: { subject }
      });
      setManagerHasGoogleCalendar(true);
    } catch (error) {
      setManagerHasGoogleCalendar(false);
      handleError(error);
    }
  };

  const getOutlookCalendar = async ({ userPrincipalName }) => {
    if (!userPrincipalName) {
      setManagerHasOutlookCalendar(false);
      return;
    }

    try {
      const response = await callAzureFunction({
        method: 'get',
        url: 'outlook-calendar/calendars',
        params: { userPrincipalName }
      });

      const { canAccessOutlookCalendar } = response?.data || {};
      setManagerHasOutlookCalendar(canAccessOutlookCalendar);
    } catch (error) {
      setManagerHasOutlookCalendar(false);
      handleError(error);
    }
  };

  useEffect(() => {
    if (appConfig?.integrations?.googleCalendar?.calendarApiRead) getGoogleCalendar({ subject: selectedMSP?.emailAddress });
    if (appConfig?.integrations?.outlookCalendar?.calendarApiRead) getOutlookCalendar({ userPrincipalName: selectedMSP?.emailAddress });
  }, [selectedMSP, appConfig, changeDateTime]);

  const getTimeSlots = useCallback(async () => {
    try {
      startLoadingTimeSlots();

      let groundZeroLocation;

      if (selectedMSP?.gzLocations && selectedMSP?.gzLocations.length > 0) {
        groundZeroLocation = getSelectedGZLocationPerRRule(newDate, selectedMSP.gzLocations);
      }

      const timeSlots = await getMobileServiceProviderTimeSlots({
        date: newDate,
        service,
        selectedGroundZeroLocation: { ...groundZeroLocation, emailAddress: selectedMSP?.emailAddress, managerId: selectedMSP?.id },
        travelTimeBuffer: selectedMSP?.travelTimeBufferMinutes,
        ignoreGroundZeroLocation,
        timezonePerMobileBookingPlaceId
      });

      setAvailableTimeSlots(timeSlots);

      if (!ignoreGroundZeroLocation) {
        setNewGroundZeroLocation(groundZeroLocation);
      }
    } catch (error) {
      handleError(error);
    } finally {
      stopLoadingTimeSlots();
    }
  }, [service, newDate, selectedMSP, ignoreGroundZeroLocation, timezonePerMobileBookingPlaceId]);

  const getServices = useCallback(async () => {
    try {
      setLoadingServices(true);

      const response = await callAzureFunction({
        url: 'services-by-manager',
        method: 'get',
        params: { managerId: manager?.id }
      });
      const { rows } = response.data;

      setServices(rows);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingServices(false);
    }
  }, [manager?.id]);

  const getMSPByService = useCallback(async () => {
    try {
      setLoadingAvailableManagers(true);

      const response = await callAzureFunction({
        url: 'managers-by-service',
        method: 'get',
        params: { serviceId: service.id }
      });
      const { rows } = response.data;

      setAvailableManagersMSP(rows);

      if (rows.length) {
        const managerGroundZeroLocations = [];

        rows
          .filter((each) => each.ManagerGroundZeroLocations.length > 0 && Number(each?.id) !== Number(manager?.id))
          .forEach((manager) => {
            const managerData = {
              managerId: manager.id,
              managerName: `${manager.firstName} ${manager.lastName}`,
              emailAddress: manager.emailAddress,
              travelTimeBufferMinutes: manager.travelTimeBufferMinutes
            };

            manager.ManagerGroundZeroLocations.forEach((gzLocation) => {
              const gzLocationData = {
                id: gzLocation.id,
                description: gzLocation.address,
                workDays: gzLocation.workDays?.split(',')?.map((d) => Number(d)) || [],
                ...gzLocation,
                ...managerData
              };

              managerGroundZeroLocations.push(gzLocationData);
            });
          });

        setAvailableGZLocations(managerGroundZeroLocations);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingAvailableManagers(false);
    }
  }, [service, manager]);

  const handleServiceSwitch = (event) => {
    const { checked } = event.target;

    // if manually toggling service change, don't allow changing others
    setDisableChangeManager(checked);
    setChangeManager(false);
    setDisableChangeDateTime(checked);
    setChangeDateTime(false);

    setNewManagerOption(null);
    setNewManager(null);
    setNewDate(null);
    setNewTimeSlot(null);

    if (!checked) {
      setError(null);
      setNewService(null);
    }

    setChangeService(checked);
  };

  const handleManagerSwitch = (event) => {
    const { checked } = event.target;

    if (!checked) {
      setError(null);
      setNewManagerOption(null);
      setNewManager(null);
    }

    setChangeManager(checked);
  };

  const handleDateTimeSwitch = (event) => {
    const { checked } = event.target;

    if (checked) {
      setNewStatus(bookingStatuses.Upcoming);
      if (changeManager && !newManager) {
        setChangeManager(false);
      }
    } else {
      setError(null);
      setNewTimeSlot(null);
    }

    setChangeDateTime(checked);
  };

  const verifyMSPWeeklyServiceLimit = useCallback(async () => {
    try {
      const bookingDate = dayjs(newDate).format('YYYY-MM-DD');
      const managerId = changeManager ? newManager?.id : manager?.id;
      const serviceId = changeService ? newService?.id : service?.id;

      const params = { serviceId, managerId, date: bookingDate };
      const response = await callAzureFunction({ url: 'public/bookings-verify-MSP-service-limit', method: 'get', params });

      const { canMSPTakeTheBooking, bookingsCountForTheWeek, limitPerMSP } = response.data;

      if (!canMSPTakeTheBooking) {
        enqueueSnackbar({
          variant: 'error',
          message: (
            <Typography
              sx={{ maxWidth: 500, px: 1 }}
            >{`The MSP cannot be booked on ${bookingDate} as the MSP has already reached the service limit of ${limitPerMSP} per week (since booked with a total of ${bookingsCountForTheWeek} ${bookingEntityName?.toLowerCase()}). Please pick another date.`}</Typography>
          ),
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        });
        setNewTimeSlot(null);
      }

      return canMSPTakeTheBooking;
    } catch (error) {
      handleError(error);
    }

    return false;
  }, [enqueueSnackbar, bookingEntityName, changeManager, newManager, manager, changeService, service, newService, newDate]);

  const attemptUpdateBooking = async (options = { unsafe: false }) => {
    try {
      setError(null);
      setUpdatingBooking(true);
      const errors = [];

      // change service checks
      if (changeService && !newService) {
        errors.push(`Please select a service or deselect your request to change service`);
      }

      // reschedule checks
      if (changeDateTime && !ignoreGroundZeroLocation) {
        if (!newDate || !newTimeSlot) {
          errors.push('Date and Time are required for rescheduling');
        }
        if (newDate && newTimeSlot && !dateIsValidAtLocation(newDate, newTimeSlot, selectedGroundZeroLocation)) {
          errors.push('Selected Date/Time has already passed at the location');
        }
      }

      if (errors.length) {
        setError(errors.join('. '));
      } else {
        setError(null);

        const data = {
          status: newStatus?.value || null,
          isMobileBooking: true
        };
        if (options.unsafe) {
          data.updateUnsafeWithoutChecking = true;
        }
        if (changeService) {
          data.date = date;
          data.service = newService;
          data.startHours = startHours;
          data.startMinutes = startMinutes;
          const startTime = dayjs(date).hour(startHours).minute(startMinutes);
          data.endHours = dayjs(startTime).add(newService.duration, 'minute').hour();
          data.endMinutes = dayjs(startTime).add(newService.duration, 'minute').minute();
        }

        if (changeManager) {
          data.managerOption = managerLocationOptions.specific.value;
          data.manager = newManager;
        }

        if (changeDateTime) {
          // date and time
          data.date = dayjs(newDate).format('YYYY-MM-DD');
          data.startHours = dayjs(newTimeSlot.start).hour();
          data.startMinutes = dayjs(newTimeSlot.start).minute();
          data.endHours = dayjs(newTimeSlot.end).hour();
          data.endMinutes = dayjs(newTimeSlot.end).minute();
          data.respondentAvailabilityIsKnown = true;
          data.status = bookingStatuses.Upcoming.value;
          data.managerGroundZeroLocationId = selectedGroundZeroLocation?.id;
          data.travelTimeBufferMinutes = selectedMSP?.travelTimeBufferMinutes || 0;
        }

        // passed to get busy times of a manager as per Outlook and Google calendar event
        data.timezone = selectedGroundZeroLocation?.timezone;

        let updateUrl;
        if (user.type === userTypes.Admin) {
          updateUrl = `/bookings/${booking.id}`;
        } else if (user.type === userTypes.Manager) {
          updateUrl = `/bookings/${booking.id}/as-manager`;
        }

        await callAzureFunction({ url: updateUrl, method: 'put', data });

        const targetStatus = [bookingStatuses.Canceled.value, bookingStatuses.DidNotAttend.value, affiliateBookingStatuses.Confirmed.value];
        const changeInStatus = targetStatus.includes(newStatus?.value);

        const shouldBookingActionSnackbarAppear = changeService || changeDateTime || changeInStatus;

        if (shouldBookingActionSnackbarAppear) {
          const serviceChanged = Boolean(currentService?.id !== newService?.id && changeService);

          const messageType = getSnackbarMessageType({
            serviceChanged,
            changeInDateTime: changeDateTime,
            changeInStatus,
            status: newStatus?.value
          });

          enqueueSnackbar({
            variant: 'BOOKING_ACTION',
            autoHideDuration: 20000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            variantProps: {
              bookingId: booking.id,
              respondentId: booking.respondentId,
              messageType,
              isMobileBooking: true
            }
          });
        }

        handleClose();
        // don't reload booking on manage booking calendar, as websocket message will cause reload here already
        if (pageLocation?.pathname !== '/manage-booking-calendar' || enableStatusUpdate === false) {
          getBookings();
        }
      }
    } catch (error) {
      setError(handleError(error));
    } finally {
      setUpdatingBooking(false);
    }
  };

  const handleUpdateBookingButton = async () => {
    const bookingIsSafe = await isBookingSafe();

    let MSPCanTakeTheBooking = true;

    if (newStatus === bookingStatuses.Upcoming.value || isUnconfirmedBooking) {
      // execute the weekly service limit check
      // if the new status is upcoming
      // or the booking being updated is an unconfirmed mobile booking

      MSPCanTakeTheBooking = await verifyMSPWeeklyServiceLimit();
    }

    if (bookingIsSafe && MSPCanTakeTheBooking) {
      attemptUpdateBooking({ unsafe: false });
    }
  };

  const handleSendReminder = async () => {
    setOpen(false);
    setViewEmailBookingDialogOpen(true);
  };

  // const handleOpenChangeBookingTypeDialog = () => {
  //   setOpen(false);
  //   setOpenChangeBookingTypeDialog(true);
  // };

  useEffect(() => {
    // get new services whenever location is changed
    // this is smoother for updating service selections automatically than previous
    // which waited for user to click on the services dropdown
    if (manager?.id && changeService) {
      getServices();
    }
  }, [getServices, manager?.id, changeService]);

  useEffect(() => {
    // get new MSP roster whenever services is changed
    // this is smoother for updating service selections automatically than previous
    // which waited for user to click on the services dropdown
    if (service?.id && changeManager) {
      getMSPByService();
    }
  }, [getMSPByService, service?.id, changeManager, manager]);

  useEffect(() => {
    // side effect
    if (availableGZLocations.length > 0 && changeManager) {
      getClosestMSPs();
    }
  }, [changeManager, getClosestMSPs, availableGZLocations]);

  // allow date and time to
  useEffect(() => {
    if (newManager) {
      setChangeDateTime(true);
    }
  }, [newManager]);

  useEffect(() => {
    if (changeDateTime && booking?.date) {
      setNewDate(dayjs(booking.date));
    }
  }, [changeDateTime, booking]);

  useEffect(() => {
    getTimeSlots();
  }, [getTimeSlots, newDate]);

  // useEffect(() => {
  //   if (newManager && changeManager) {
  //     setNewDate(dayjs(booking.date));
  //     setNewTimeSlot(null);
  //   }
  // }, [changeManager, newManager, booking]);

  const setNewServiceHelper = (newService) => {
    setNewService(newService);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        {loadingBooking ? (
          <DialogContent>
            <Skeleton variant="rectangular" height={250} />
          </DialogContent>
        ) : (
          <>
            {booking.id ? (
              <>
                <DialogTitle variant="h3">
                  Mobile {bookingEntityName} for {respondent ? `${respondent.firstName} ${respondent.lastName}` : '-'}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2} mt={2}>
                    {/* status */}
                    <Grid container item xs={6} direction="row">
                      {(user.type === userTypes.Admin ||
                        (user.type === userTypes.Manager && permissions.includes(ManagerAccessRolePermissions.BOOKING_WRITE.key))) &&
                      enableStatusUpdate ? (
                        <Grid container item alignItems="center">
                          <Typography>Status: </Typography>
                          <Autocomplete
                            id="select-new-status"
                            options={getStatusOptions()}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            getOptionLabel={(option) => (option ? option.label : '')}
                            value={newStatus}
                            onChange={(_, value) => setNewStatus(value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: newStatus ? <FiberManualRecord style={{ color: newStatus.color, fontSize: 18 }} /> : null
                                }}
                              />
                            )}
                            sx={{ maxWidth: 200 }}
                            size="small"
                            fullWidth
                            renderOption={(props, option) => (
                              <li {...props}>
                                <FiberManualRecord style={{ color: option.color, fontSize: 18, marginRight: '10px' }} />
                                {option.label}
                              </li>
                            )}
                            style={{ marginLeft: '5px' }}
                          />
                        </Grid>
                      ) : (
                        <Typography>
                          Status: {bookingStatus ? <span style={{ color: bookingStatus.color }}>{bookingStatus.label}</span> : '-'}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} mt={2}>
                      <Stack direction="row" alignItems="center" gap={1} />
                    </Grid>

                    {/* respondent */}
                    <Grid item xs={12} sm={6}>
                      <Typography style={{ display: 'flex', alignItems: 'center' }}>
                        {respondentEntityName}:
                        {respondent ? (
                          <Link
                            to={`/respondents/view-respondent/${respondent.id}`}
                            style={{ marginLeft: '4px' }}
                          >{`${respondent.firstName} ${respondent.lastName}`}</Link>
                        ) : (
                          ' -'
                        )}
                      </Typography>
                    </Grid>

                    {/* manager */}
                    <Grid item xs={12} sm={6}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>
                          Mobile Service Provider (MSP): {manager?.id ? `${manager?.firstName} ${manager?.lastName}` : 'No MSP assigned'}
                        </Typography>
                      </Stack>
                    </Grid>

                    {/* client address */}
                    <Grid item xs={12} sm={6}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>Location: {mobileBookingClientAddress || 'N/A'}</Typography>
                      </Stack>
                    </Grid>

                    {/* location
                    <Grid item xs={12} sm={6}>
                      <Typography style={{ display: 'flex', alignItems: 'center' }}>
                        Location:
                        <>
                          {user.type === userTypes.Admin && affiliateSelected && (
                            <TextField
                              style={{ marginLeft: '4px' }}
                              value={newAffiliateLocation || affiliateLocation || ''}
                              onChange={(e) => setNewAffiliateLocation(e.target.value)}
                            />
                          )}
                          {user.type === userTypes.Admin && !affiliateSelected && currentLocation?.deletedAt && (
                            <Tooltip title="This location is currently archived in the system." placement="right">
                              <Typography sx={{ ml: 0.5 }}>{currentLocation.name} (Archived)</Typography>
                            </Tooltip>
                          )}
                          {user.type === userTypes.Admin && !affiliateSelected && currentLocation && (
                            <Link to={`/manage-locations/edit-location/${currentLocation.id}`} style={{ marginLeft: '4px' }}>
                              {`${currentLocation?.name}`}
                            </Link>
                          )}
                          {user.type === userTypes.Admin && !affiliateSelected && !currentLocation && '-'}
                          {user.type === userTypes.Manager && currentLocation?.deletedAt && (
                            <Tooltip title="This location is currently archived in the system." placement="right">
                              <Typography sx={{ ml: 0.5 }}>{currentLocation.name} (Archived)</Typography>
                            </Tooltip>
                          )}
                          {user.type === userTypes.Manager && currentLocation && (
                            <Typography sx={{ ml: 0.5 }}>{currentLocation.name}</Typography>
                          )}
                          {user.type === userTypes.Manager && !currentLocation && '-'}
                        </>
                      </Typography>
                    </Grid> */}

                    <Grid item xs={12} sm={6}>
                      <Stack direction="column" spacing={1} alignItems="left">
                        <Typography fullWidth display="inline">
                          MSP Ground Zero Locations
                          <Tooltip
                            title={
                              manager?.ManagerGroundZeroLocations?.length && (
                                <List>
                                  {manager?.ManagerGroundZeroLocations.map((gzLoc) => (
                                    <ListItem>{gzLoc.address}</ListItem>
                                  ))}
                                </List>
                              )
                            }
                            placement="right"
                          >
                            <InfoIcon sx={{ verticalAlign: 'middle', fontSize: 20, mb: 0.5, ml: 1 }} />{' '}
                          </Tooltip>
                        </Typography>
                      </Stack>
                    </Grid>

                    {/* bookingId */}
                    {bookingId && (
                      <Grid item xs={12} sm={6} alignItems="center" sx={{ display: 'flex' }}>
                        <Typography>AtlasOne Booking No: {bookingId}</Typography>
                      </Grid>
                    )}

                    {/* service */}
                    <Grid item xs={12} sm={6}>
                      <Typography style={{ display: 'flex', alignItems: 'center' }}>
                        Service:
                        {currentService ? (
                          <>
                            {user.type === userTypes.Admin && (
                              <>
                                {currentService?.deletedAt ? (
                                  <Tooltip title="This service is currently archived in the system." placement="right">
                                    <Typography sx={{ ml: 0.5 }}>{currentService.name} (Archived)</Typography>
                                  </Tooltip>
                                ) : (
                                  <Link
                                    to={`/manage-services/edit-service/${currentService.id}`}
                                    style={{ marginLeft: '4px' }}
                                  >{`${currentService.name}`}</Link>
                                )}
                              </>
                            )}
                            {user.type === userTypes.Manager && (
                              <>
                                {currentService?.deletedAt ? (
                                  <Tooltip title="This service is currently archived in the system." placement="right">
                                    <Typography sx={{ ml: 0.5 }}>{currentService.name} (Archived)</Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography sx={{ ml: 0.5 }}>{currentService.name}</Typography>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          ' - '
                        )}
                      </Typography>
                    </Grid>

                    {/* date */}
                    <Grid item xs={12} sm={6} alignItems="center" sx={{ display: 'flex' }}>
                      <Typography>
                        {isUnconfirmedBooking
                          ? `Preferred Date : ${formattedDate}`
                          : `Date: ${respondentAvailabilityIsKnown ? formattedDate : '-'}`}
                      </Typography>
                    </Grid>

                    {/* time */}
                    <Grid item xs={12} sm={6}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography textTransform="capitalize">
                          {isUnconfirmedBooking ? (
                            <>
                              {preferredTimeFrame ? (
                                <>Preferred Time Frame: {getPreferredTimeFrameLabel(preferredTimeFrame)}</>
                              ) : (
                                `Preferred Time : ${preferredTimeFormatted}`
                              )}
                            </>
                          ) : (
                            `Time: ${respondentAvailabilityIsKnown ? formattedTime : '-'}`
                          )}
                        </Typography>
                      </Stack>
                    </Grid>

                    {/* created from */}
                    {bookingCreatedFrom && (
                      <Grid item xs={12} sm={6} alignItems="center" sx={{ display: 'flex' }}>
                        <Typography>Created From: {bookingCreatedFrom}</Typography>
                      </Grid>
                    )}

                    {/* travel time */}
                    <Grid item xs={12} sm={6}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>
                          MSP Travel Buffer Time: {travelTimeBufferMinutes ? `${travelTimeBufferMinutes} Minutes` : 'N/A'}
                        </Typography>
                      </Stack>
                    </Grid>

                    {/* Email Notification */}
                    <Grid item xs={12} sm={6} alignItems="center" sx={{ display: 'flex' }}>
                      {!isLoadingEmailEvent ? (
                        <Typography>
                          Notification:{' '}
                          {latestEmailEvent
                            ? `Last email sent on ${latestEmailEventTime?.format('MMMM D, YYYY')} at ${latestEmailEventTime.format('hh:mm A')} to ${latestEmailEvent.to_email}`
                            : 'No data available'}
                          <Tooltip
                            title={
                              <>
                                {latestEmailEvent
                                  ? `This log pertains to the latest email sent to the ${respondentEntityName?.toLowerCase()}
                                's primary email address. Refers to the email subject - ${latestEmailEvent.subject}`
                                  : `This indicates that either no
                                email has been sent to the ${respondentEntityName?.toLowerCase()}, or the email log has expired. Email
                                logs are only retained for up to 30 days.`}
                                <br />
                                <br />
                                <span>
                                  If you have recently sent an email through the system or are expecting a system reminder email, it may
                                  take a few minutes for the log to update.
                                </span>
                              </>
                            }
                            placement="top"
                          >
                            <InfoIcon sx={{ verticalAlign: 'middle', fontSize: 20, mb: 0.5, ml: 1 }} />{' '}
                          </Tooltip>
                        </Typography>
                      ) : (
                        <>
                          <Grid direction="row" item xs={12}>
                            <Grid container direction="row" alignItems="center">
                              <Grid item>
                                <Typography display="inline" mr={1}>
                                  Email Notification:{' '}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Skeleton width={205} />
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Skeleton width={335} />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} alignItems="center" sx={{ display: 'flex' }}>
                      <Typography display="inline" mr={1} sx={{ textTransform: latestEmailEvent?.status ? 'capitalize' : 'none' }}>
                        Email Status: {!isLoadingEmailEvent && <>{latestEmailEvent?.status?.replace('_', ' ') || 'No data available'}</>}{' '}
                      </Typography>
                      {isLoadingEmailEvent && <Skeleton width={150} ml={2} />}
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    {!isReadOnly && (
                      <>
                        {user.type === userTypes.Admin && canDetailsUpdate && (
                          <Grid container item xs={12} justifyContent="space-around">
                            {!isUnconfirmedBooking && (
                              <>
                                {/* change service */}
                                <Grid item xs={12} sm={3}>
                                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                    <Typography>Change Service?</Typography>
                                    <Switch checked={changeService} onChange={handleServiceSwitch} disabled={disableChangeService} />
                                  </Stack>
                                </Grid>
                              </>
                            )}

                            <>
                              {/* change nearby MSP at location */}
                              <Grid item xs={12} sm={3}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                  {ignoreGroundZeroLocation ? (
                                    <>
                                      <Typography display="inline">Assign {managerEntityName} (MSP)</Typography>
                                      <Tooltip
                                        placement="top"
                                        title="Note: The MSP options shown here are not limited to their respective ground zero locations."
                                      >
                                        <InfoIcon sx={{ fontSize: 20, verticalAlign: 'middle', ml: 0.5 }} color="primary" />
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <Typography>Change Nearby {managerEntityName} (MSP) at Location?</Typography>
                                  )}
                                  <Switch
                                    checked={changeManager}
                                    onChange={handleManagerSwitch}
                                    disabled={disableChangeManager || !mobileBookingPlaceId}
                                  />
                                </Stack>
                              </Grid>
                            </>

                            {/* change date and time */}
                            <Grid item xs={12} sm={3}>
                              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                <Typography>Change Date & Time?</Typography>
                                <Tooltip title="Select a MSP first before setting the date and time" disableHoverListener={selectedMSP}>
                                  <span>
                                    <Switch
                                      checked={changeDateTime}
                                      onChange={handleDateTimeSwitch}
                                      disabled={disableChangeDateTime || !selectedMSP}
                                    />
                                  </span>
                                </Tooltip>
                              </Stack>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}

                    {/* service options for change service */}
                    {user.type === userTypes.Admin && changeService && (
                      <Grid item xs={12}>
                        <Divider />
                        <Autocomplete
                          id="select-service"
                          loading={loadingServices}
                          options={services}
                          isOptionEqualToValue={(option, value) => option?.id === value.id}
                          getOptionLabel={(option) => (option?.id ? `${option.name}` : '')}
                          value={newService}
                          onChange={(_, value) => setNewServiceHelper(value)}
                          renderInput={(params) => <TextField {...params} label="Service" />}
                          fullWidth
                        />
                      </Grid>
                    )}

                    {/* manager options for change manager */}
                    {user.type === userTypes.Admin && changeManager && (
                      <>
                        <Grid item xs={12}>
                          <Divider />
                          <Grid container spacing={1} mb={2} mt={2}>
                            {!ignoreGroundZeroLocation && (
                              <>
                                {isLoadingClosestMSPs ? (
                                  <Grid item xs={12} container alignItems="center" justifyContent="center">
                                    <CircularProgress />
                                  </Grid>
                                ) : (
                                  <>
                                    {/* closest locations */}
                                    {closestMSPs.length > 0 ? (
                                      <Grid item xs={12}>
                                        <Grid container spacing={gridSpacing}>
                                          {closestMSPs.map((closestMSP) => (
                                            <Grid item key={closestMSP.managerId} xs={12} sm={6}>
                                              <Card
                                                variant="outlined"
                                                sx={{
                                                  p: 2,
                                                  cursor: 'pointer',
                                                  borderColor: newManager?.managerId === closestMSP.managerId && theme.palette.primary.main,
                                                  borderWidth: newManager?.managerId === closestMSP.managerId && 3
                                                }}
                                                onClick={() => setNewManager({ ...closestMSP, id: closestMSP.managerId })}
                                              >
                                                <>
                                                  <Stack direction="row" justifyContent="space-between">
                                                    <Typography variant="h3">{closestMSP?.managerName}</Typography>
                                                    {closestMSP && (
                                                      <Box>
                                                        {weekDays
                                                          .filter((weekDay) => {
                                                            // show only the workdays per nearest closest location
                                                            const { overallGZWorkDays } = closestMSP;
                                                            return overallGZWorkDays?.includes(weekDay.value);
                                                          })
                                                          .map((weekDay) => (
                                                            <Chip
                                                              size="small"
                                                              key={weekDay.label}
                                                              value={weekDay.value}
                                                              label={weekDay.label}
                                                            />
                                                          ))}
                                                      </Box>
                                                    )}
                                                  </Stack>
                                                </>
                                                <Stack direction="row" spacing={1} mt={1} alignItems="center">
                                                  {closestMSP.gzLocations.length > 0 && (
                                                    <Chip
                                                      icon={<DirectionsCarIcon />}
                                                      label={closestMSP.gzLocations[0].travelDistanceText}
                                                      variant="outlined"
                                                    />
                                                  )}
                                                </Stack>
                                              </Card>
                                            </Grid>
                                          ))}
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      <Grid item xs={12} container justifyContent="space-between" mx={2}>
                                        <>
                                          <>
                                            <Grid item xs={9}>
                                              <Typography variant="body1">
                                                {`Could not determine any nearby service provider. Would you like to assign a MSP regardless of the
                                        MSP's ground zero location/s?`}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={3} justifyContent="center" container>
                                              <Button onClick={() => setIgnoreGroundZeroLocation(true)}>OK</Button>
                                            </Grid>{' '}
                                          </>
                                        </>
                                      </Grid>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {ignoreGroundZeroLocation && (
                              <Grid item xs={12}>
                                <Autocomplete
                                  id="select-manager"
                                  loading={loadingAvailableManagers}
                                  options={availableManagersMSP}
                                  isOptionEqualToValue={(option, value) => option?.id === value.id}
                                  getOptionLabel={(option) => (option?.id ? `${option.firstName} ${option.lastName}` : '')}
                                  value={newManager}
                                  onChange={handleAssignMSPwithGroundZeroLocationsIgnored}
                                  onOpen={getMSPByService}
                                  renderInput={(params) => <TextField {...params} label={`Select ${managerEntityName}`} />}
                                  fullWidth
                                  noOptionsText="No MSP is set to be qualified to take the service."
                                  disabled={!service?.id}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {/* date and time picker for rescheduling */}
                    {!affiliateSelected && user.type === userTypes.Admin && changeDateTime && (
                      <Grid item xs={12}>
                        <Divider />
                        <Grid container spacing={3}>
                          {/* date picker */}
                          <Grid item xs={12} sm={12} md={6}>
                            <CustomStaticDatePicker
                              value={dayjs(newDate)}
                              onChange={(value) => setNewDate(value)}
                              slotProps={{ actionBar: { actions: [] } }}
                              disablePast
                              shouldDisableDate={(value) =>
                                selectedMSP?.gzLocations?.length
                                  ? !dateIsValidPerRRulesOfAllGZLocations(value, selectedMSP.gzLocations)
                                  : false
                              }
                            />
                          </Grid>
                          {/* time (slot) picker */}
                          <Grid item xs={12} sm={12} md={6} direction="column" mt={matchesDownMd ? 0 : 12}>
                            <Grid item xs={6}>
                              <Stack direction="row" sx={{ display: 'absolute', mt: -6, mb: 2 }}>
                                {managerHasGoogleCalendar && (
                                  <HtmlTooltip
                                    disableFocusListener
                                    title="Google Calendar events from the selected MSP are being considered for the available time slots."
                                    placement="top"
                                  >
                                    <IconButton>
                                      <GoogleIcon />
                                    </IconButton>
                                  </HtmlTooltip>
                                )}
                                {managerHasOutlookCalendar && (
                                  <HtmlTooltip
                                    disableFocusListener
                                    title="Outlook Calendar events from the selected MSP are being considered for the available time slots."
                                    placement="top"
                                  >
                                    <IconButton>
                                      <MicrosoftIcon />
                                    </IconButton>
                                  </HtmlTooltip>
                                )}
                                <Box sx={{ height: 40 }} />
                              </Stack>
                            </Grid>
                            <Grid item xs={6}>
                              {/* time slots */}
                              {loadingTimeSlots ? (
                                <Skeleton variant="rectangular" height={250} />
                              ) : (
                                <>
                                  {availableTimeSlots.length > 0 ? (
                                    <Grid container spacing={1} sx={{ maxHeight: 190, overflow: 'auto' }}>
                                      {availableTimeSlots.map((slot) => (
                                        <Grid item key={slot.startText} xs={12} sm={6} md={4}>
                                          <Card
                                            variant="outlined"
                                            sx={{
                                              p: 2,
                                              cursor: 'pointer',
                                              textAlign: 'center',
                                              borderColor: newTimeSlot?.startText === slot.startText && theme.palette.primary.main,
                                              borderWidth: newTimeSlot?.startText === slot.startText && 3
                                            }}
                                            onClick={() => setNewTimeSlot(slot)}
                                          >
                                            <Typography variant="h5">{slot.startText}</Typography>
                                          </Card>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  ) : (
                                    <Typography>No available time slots.</Typography>
                                  )}
                                  {/* custom time picker */}
                                  <Stack sx={{ mt: 3 }}>
                                    <TimePicker
                                      value={newTimeSlot?.start ? dayjs(newTimeSlot.start) : null}
                                      onChange={(value) => {
                                        if (value) {
                                          const start = dayjs(value);
                                          const end = start.add(service.duration, 'minute');
                                          const timeSlot = {
                                            start,
                                            startText: start.format('hh:mm a'),
                                            end,
                                            endText: end.format('hh:mm a')
                                          };
                                          setNewTimeSlot(timeSlot);
                                        } else {
                                          setNewTimeSlot(null);
                                        }
                                      }}
                                      slots={{ textField: CustomTimeTextField }}
                                    />
                                  </Stack>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  {error && (
                    <Grid item xs={12}>
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {error}
                      </Alert>
                    </Grid>
                  )}
                </DialogContent>
                <DialogActions>
                  {/* delete booking */}
                  {user.type === userTypes.Admin && !isReadOnly && (
                    <Button color="error" onClick={handleDeleteConfirmation}>
                      Delete
                    </Button>
                  )}
                  {/* update booking */}
                  {(user.type === userTypes.Admin ||
                    (user.type === userTypes.Manager && permissions.includes(ManagerAccessRolePermissions.BOOKING_WRITE.key))) &&
                    shouldUpdateBTNRender() && (
                      <Tooltip
                        title="Complete all the details to proceed with the updating"
                        disableHoverListener={canUpdateBooking()}
                        placement="top"
                      >
                        <span>
                          <LoadingButton loading={updatingBooking} onClick={handleUpdateBookingButton} disabled={!canUpdateBooking()}>
                            <span>Update {bookingEntityName}</span>
                          </LoadingButton>
                        </span>
                      </Tooltip>
                    )}
                  {/* send booking reminder */}
                  {user.type === userTypes.Admin && bookingStatus?.value === bookingStatuses.Upcoming.value && (
                    <LoadingButton onClick={handleSendReminder}>
                      <span>Send Notification</span>
                    </LoadingButton>
                  )}
                  {/* close dialog */}
                  <Button variant="contained" onClick={handleClose} disabled={updatingBooking}>
                    Close
                  </Button>
                </DialogActions>
              </>
            ) : (
              <Typography>{bookingEntityName} not found</Typography>
            )}
          </>
        )}
      </Dialog>

      <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="h5">{`Type 'DELETE' to confirm deletion.`}</Typography>
          <TextField
            autoFocus
            type="text"
            fullWidth
            value={deleteTypedText}
            onChange={(event) => setDeleteTypedText(event.target.value.toUpperCase())}
            style={{ paddingTop: '5px' }}
          />
          <FormControlLabel
            control={<Checkbox checked={deleteAssociatedSurvey} onChange={(e) => setDeleteAssociatedSurvey(e.target.checked)} />}
            label={`Also delete any ${surveysEntityName} that are associated with this ${bookingEntityName}?`}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={deleteTypedText !== 'DELETE'} onClick={handleDelete}>
            Confirm Delete
          </Button>
          <Button variant="contained" onClick={handleCancelDelete}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={deleteSnackbarOpen}
        onClose={() => {
          setDeleteSnackbarOpen(false);
        }}
        message={deleteSnackbarMessage}
        autoHideDuration={3000}
      />

      <Dialog
        open={collisionDialog.open}
        onClose={() => {
          setCollisionDialog({ open: false, title: '', content: '' });
        }}
        maxWidth="xs"
      >
        <DialogTitle>{collisionDialog.title}</DialogTitle>
        <DialogContent>{collisionDialog.content}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCollisionDialog({ open: false, title: '', content: '' });
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setCollisionDialog({ open: false, title: '', content: '' });
              attemptUpdateBooking({ unsafe: true });
            }}
          >
            Continue Anyway
          </Button>
        </DialogActions>
      </Dialog>
      <EmailBookingDialog
        setViewDialogOpen={setOpen}
        setOpen={setViewEmailBookingDialogOpen}
        open={viewEmailBookingDialogOpen}
        bookingId={bookingId}
      />
      <ChangeBookingTypeDialog
        open={openChangeBookingTypeDialog}
        setOpen={setOpenChangeBookingTypeDialog}
        onBack={() => setOpen(true)}
        booking={booking}
        onUpdated={() => {
          setOpen(false);
          getBookings();
        }}
      />
    </>
  );
};

ViewMobileBookingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  bookingId: PropTypes.string.isRequired,
  getBookings: PropTypes.func, // optional as BookingActionSnackbar will not pass a getBookings prop when using this dialog
  enableStatusUpdate: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool // passed as true if the ViewBookingDialog has originated from the BookingActionSnackbar (notistack)
};

export default ViewMobileBookingDialog;
