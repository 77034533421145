import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bookingFilter: {
    tab: '',
    startDate: null,
    endDate: null,
    statuses: []
  }
};

const dashboardActionSlice = createSlice({
  name: 'dashboardAction',
  initialState,
  reducers: {
    setBookingFilter: (state, action) => {
      const { endDate, startDate, statuses, tab } = action.payload;
      state.bookingFilter.startDate = startDate || null;
      state.bookingFilter.endDate = endDate || null;
      state.bookingFilter.statuses = statuses || [];
      state.bookingFilter.tab = tab || '';
    },
    clearBookingFilter: (state) => {
      state.bookingFilter.startDate = null;
      state.bookingFilter.endDate = null;
      state.bookingFilter.statuses = [];
      state.bookingFilter.tab = '';
    }
  }
});

export const { setBookingFilter, clearBookingFilter } = dashboardActionSlice.actions;

export default dashboardActionSlice.reducer;
