import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tablePageSize: 25,
  selectedChartTypeFromSingledayMetric: 'Pie'
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTablePageSize: (state, action) => {
      state.tablePageSize = action.payload;
    },
    setSelectedChartTypeFromSingledayMetric: (state, action) => {
      state.selectedChartTypeFromSingledayMetric = action.payload;
    }
  }
});

export const { setTablePageSize, setSelectedChartTypeFromSingledayMetric } = appSlice.actions;

export default appSlice.reducer;
