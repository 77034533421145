import { RRule } from 'rrule';

const weekDays = [
  {
    value: 0,
    label: 'Sun',
    rruleValue: RRule.SU
  },
  {
    value: 1,
    label: 'Mon',
    rruleValue: RRule.MO
  },
  {
    value: 2,
    label: 'Tue',
    rruleValue: RRule.TU
  },
  {
    value: 3,
    label: 'Wed',
    rruleValue: RRule.WE
  },
  {
    value: 4,
    label: 'Thu',
    rruleValue: RRule.TH
  },
  {
    value: 5,
    label: 'Fri',
    rruleValue: RRule.FR
  },
  {
    value: 6,
    label: 'Sat',
    rruleValue: RRule.SA
  }
];

// dark blue
// #1c1463

// robin egg blue
// #00c4c4

// mint
// #1ad19e

const red = '#FF5050';

const bookingStatuses = {
  Upcoming: {
    value: 'upcoming',
    label: 'Upcoming',
    color: '#71e1c3',
    title: 'Scheduled Booking'
  },
  Completed: {
    value: 'completed',
    label: 'Completed',
    color: '#bcbcbc',
    title: 'Completed Booking'
  },
  Autocompleted: {
    value: 'auto-completed',
    label: 'Auto-Completed',
    color: '#ABB0B8',
    title: 'Auto-completed Booking'
  },
  Canceled: {
    value: 'canceled',
    label: 'Canceled',
    color: red,
    title: 'Cancelled Booking'
  },
  DidNotAttend: {
    value: 'dna',
    label: 'DNA',
    color: '#FFA500',
    title: 'Did Not Attend Booking'
  }
};

const affiliateBookingStatuses = {
  Unconfirmed: {
    value: 'unconfirmed',
    label: 'Unconfirmed',
    color: '#71e1c3',
    chartColor: '#00a1a1'
  },
  Confirmed: {
    value: 'confirmed',
    label: 'Confirmed',
    color: '#bcbcbc',
    chartColor: '#00c4c4'
  }
};

const BookingTabTypes = {
  Confirmed: 'confirmed',
  Unconfirmed: 'unconfirmed',
  Affiliate: 'affiliate'
};

const bookingTypes = {
  Direct: {
    value: 'direct',
    label: 'Direct'
  },
  Referral: {
    value: 'referral',
    label: 'Referral'
  },
  MobileBooking: {
    value: 'mobile-booking',
    label: 'Mobile Booking'
  }
};

// Latitude and longitude coordinates of AtlasOpen office
const defaultLatLng = { lat: -37.7956368, lng: 144.9844614 };

const preferredTimeFrameOptions = [
  { value: 'am', label: 'AM' },
  { value: 'pm', label: 'PM' },
  { value: 'anytime', label: 'anytime' }
];

const timeSlotIntervalOptions = [
  { value: 0, label: 'Use the shortest service duration available at the location as the time slot interval.' },
  { value: 5, label: '5 min' },
  { value: 15, label: '15 min' },
  { value: 30, label: '30 min' }
];

// Dashboard

const getBookingStatusFromSelectedChartDataPoint = (status) => [
  {
    title: status.includes('Affiliate') ? status.split(' ')[1] : status,
    value: status.includes('Affiliate') ? status.split(' ')[1].toLowerCase() : status.toLowerCase()
  }
];

export {
  weekDays,
  bookingStatuses,
  affiliateBookingStatuses,
  defaultLatLng,
  preferredTimeFrameOptions,
  timeSlotIntervalOptions,
  BookingTabTypes,
  getBookingStatusFromSelectedChartDataPoint,
  bookingTypes
};
